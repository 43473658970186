<template>
  <Card
    class="home-promo-default fathers-day-bg"
    mobile-with-border
    desktop-with-border
  >
    <div class="home-promo-default__side-texts">
      <p class="title">
        <Lang by-key="home-promo-fathers-day-title" />
      </p>

      <p class="subtitle">
        <Lang by-key="home-promo-fathers-day-subtitle" />
      </p>

      <div>
        <p>
          <Lang by-key="home-promo-fathers-day-extra-1" />
        </p>
      </div>

      <div class="base-price">
        <span>
          <Lang by-key="from-value" />
        </span>
        <span class="base-price__price">15 €</span>
      </div>

      <div class="button">
        <SplNuxtLink
          is-external
          :to="promoFathersDayLink"
          no-lang
        >
          <SplButton
            variant="acent"
            size="full"
          >
            <Lang
              by-key="home-promo-fathers-day-btn-label"
              capitalize-first
            />
          </SplButton>
        </SplNuxtLink>
      </div>
    </div>

    <div>
      <Card
        class="home-promo-default__side-details"
        mobile-with-border
        desktop-with-border
      >
        <div class="image">
          <img src="~/assets/images/home/promos/spa-noel.webp">
        </div>

        <p class="title">
          <Lang by-key="home-promo-fathers-day-side-title" />
        </p>

        <div class="subtitle">
          <Lang by-key="home-promo-fathers-day-side-subtitle" />
        </div>

        <div class="base-price">
          <span>
            <Lang by-key="from-value" />
          </span>
          <span class="base-price__price">15 €</span>
        </div>
      </Card>
    </div>
  </Card>
</template>

<script lang="ts">
import Card from '../shared/Card.vue'
import SplNuxtLink from '../shared/SplNuxtLink.vue'
import SplButton from '../shared/form/SplButton.vue'

import { useLocatedLinks } from '~/core/composable/shared/useLocatedLinks'

export default defineNuxtComponent({
  components: {
    SplNuxtLink,
    SplButton,
    Card,
  },
  async setup() {
    const {
      promoFathersDayLink,
      fetchFathersDayPromo,
    } = useLocatedLinks()

    await fetchFathersDayPromo()

    return {
      promoFathersDayLink,
    }
  },
})
</script>

<style lang="scss" scoped>
.home-promo-default.fathers-day-bg {
  background-image: url('~/assets/images/home/gift-card-image.jpg');
  background-size: cover;

  background-color: rgba(12,12,12,.5);
  background-blend-mode: multiply;
}

.home-promo-default {
  @apply grid;
  @apply grid-cols-1;
  @apply grid-rows-2;
  @apply gap-5;

  @apply my-2;
  @apply p-12;

  @screen md {
    @apply my-4;
    @apply p-8;

    @apply grid-cols-2;
    @apply grid-rows-1;
  }

  @screen lg {
    @apply p-12;
  }

  &__side-texts {
    @apply text-white;

    @apply h-full;

    @apply flex;
    @apply flex-col;

    @apply justify-between;

    @apply gap-4;

    @apply py-12;

    .title {
      @apply text-4xl;
      @apply font-bold;
      @apply mb-2;
    }

    .subtitle {
      @apply text-3xl;
    }

    .base-price {
      @apply my-6;

      @apply flex;
      @apply items-center;
      @apply justify-center;
      @apply gap-2;

      @apply text-lg;

      &__price {
        @apply text-4xl;
        @apply font-bold;
      }
    }

    .button {
      @apply text-center;

      @apply px-0;
      @screen md {
        @apply px-8;
      }
    }
  }

  &__side-details {
    @apply bg-white;
    @apply h-full;

    @apply flex;
    @apply flex-col;

    @apply justify-between;

    @apply p-8;

    .image {
      img {
        @apply rounded-2xl;
        @apply w-full;
      }
    }

    .title {
      @apply font-bold;
      @apply text-spl-primary;

      @apply text-2xl;
      @apply my-1;
      @apply pt-2;
    }

    .subtitle {
      @apply text-lg;
      @apply text-spl-dark;
    }

    .base-price {
      @apply mt-4;

      @apply flex;
      @apply items-center;
      @apply justify-center;
      @apply gap-2;

      @apply text-lg;

      &__price {
        @apply text-spl-dark;

        @apply text-4xl;
        @apply font-bold;
      }
    }
  }
}
</style>
